import { useState } from "react";

import { makeGetApiCall } from "../shared/makeApiCall";

export function AuthenticationViewModel() {
  const [emptyModalOpen, setEmptyModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [formMessage, setFormMessage] = useState({
    show: false,
    message: null,
  });
  const [loading, setLoading] = useState(false);
  const storeId = localStorage.getItem("storeId");
  const userId = localStorage.getItem("userId");

  const requestAuthenticationTokenToEmail = async function (values) {
    setLoading(true);
    let callResult = await makeGetApiCall(
      "sendSubscriberToken?email=" + values.email
    );

    setFormMessage({
      show: true,
      message: callResult.data.message,
    });
    setLoading(false);
  };

  return {
    emptyModalOpen,
    formMessage,
    loading,
    requestAuthenticationTokenToEmail,
    setEmptyModalOpen,
    storeId,
    userId,
  };
}
