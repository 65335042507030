import React, { useEffect } from "react";
import { SubscriptionsViewModel } from "../../viewmodels/SubscriptionsViewModel";

import { connect } from "react-redux";

import UpdateAmountSidePanel from "../../components/modals/subscriptions/UpdateAmountSidePanel";

import { withRouter } from "../../shared/utility";

import SimpleNotification from "../../components/notifications/SimpleNotification";
import { format } from "date-fns";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import SkipSubscriptionModal from "../../components/modals/SkipSubscriptionModal";
import EmptyModal from "../../components/modals/EmptyModal";

import * as actions from "../../store/actions/index";

function Subscriptions(props) {
  const { fname, logout } = props;

  const {
    changeSubscription,
    confirmationModal,
    getFrequencies,
    getUserSubscriptions,
    formLoading,
    emptyModalOpen,
    loading,
    openCancelSubscriptionModal,
    openSkipSubscriptionModal,
    setConfirmationModal,
    setEmptyModalOpen,
    setShowSimpleNoti,
    setSkipSubscriptionModal,
    setUpdateAmountModal,
    showSimpleNoti,
    skipSubscriptionModal,
    subscriptions,
    updateAmountModal,
  } = SubscriptionsViewModel(logout);

  useEffect(() => {
    getUserSubscriptions();
    getFrequencies();
  }, []);

  return (
    <div>
      <div className="mt-10 px-3">
        <div>
          <h3 className="text-3xl font-semibold leading-7 text-gray-900">
            Hi {fname}, your active subscriptions
          </h3>
        </div>
        <div className="mt-10">
          <ul className="divide-y divide-gray-100">
            {loading ? (
              <div className="w-full items-center mt-5">
                <svg
                  className="animate-spin -ml-1 mr-3 h-10 w-10 text-indigo-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : subscriptions.length > 0 ? (
              subscriptions.map((product, index) => (
                <li
                  key={product.id}
                  className="md:flex justify-between gap-x-6 py-5"
                >
                  <div className="flex gap-x-4">
                    <img
                      className="h-12 w-12 flex-none"
                      src={product.image}
                      alt=""
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        <a
                          href={"https://fridge-filters.ca/" + product.zoeyUrl}
                          className="hover:underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {product.name} ({product.frequency_name}) - $
                          {product.price} each
                        </a>
                      </p>
                      <div className="mt-1 flex">
                        <div className="flex">
                          <p className="text-xs leading-5 text-gray-900">
                            Quantity:
                          </p>
                          <p className="ml-1 text-xs leading-5 text-gray-500">
                            {product.amount}
                          </p>
                        </div>
                        <div className="flex ml-5">
                          <p className="text-xs leading-5 text-gray-900">
                            Next shipment date:
                          </p>
                          <p className="ml-1 text-xs leading-5 text-gray-500">
                            {format(new Date(product.next_date), "LLL dd, YYY")}
                            {product.delivery_status === "6" ? (
                              <p className="text-red-600">Skipped</p>
                            ) : null}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block items-center gap-x-6 mt-5 md:mt-0">
                    <button
                      type="button"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => changeSubscription(product, index)}
                    >
                      Change subscription
                    </button>
                    <button
                      type="button"
                      className="rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 ml-3"
                      onClick={() => openSkipSubscriptionModal(product, index)}
                    >
                      Skip
                    </button>
                    <button
                      type="button"
                      className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ml-3"
                      onClick={() =>
                        openCancelSubscriptionModal(product, index)
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </li>
              ))
            ) : (
              <div>
                <p>You currently do not have any active subscriptions.</p>
              </div>
            )}
          </ul>
        </div>
      </div>
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
      />
      <EmptyModal
        childComponent={emptyModalOpen.childComponent}
        open={emptyModalOpen.open}
        setOpen={setEmptyModalOpen}
      />

      <SimpleNotification
        notificationDetails={showSimpleNoti}
        setOpen={setShowSimpleNoti}
      />
      <SkipSubscriptionModal
        skipSubscriptionModal={skipSubscriptionModal}
        setSkipSubscriptionModal={setSkipSubscriptionModal}
      />
      <UpdateAmountSidePanel
        childComponent={updateAmountModal.childComponent}
        open={updateAmountModal.open}
        setOpen={setUpdateAmountModal}
        formLoading={formLoading}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    fname: state.auth.fname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
);
