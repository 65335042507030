import EmailAuthenticationForm from "../../components/forms/EmailAuthencationForm";
import { AuthenticationViewModel } from "../../viewmodels/AuthenticationViewModel";

export default function RequestEmailAuthentication() {
  const {
    emptyModalOpen,
    formMessage,
    loading,
    requestAuthenticationTokenToEmail,
    setEmptyModalOpen,
  } = AuthenticationViewModel();

  return (
    <div className="h-full">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            src="https://cfrouting.zoeysite.com/cdn-cgi/image/format=auto,fit=scale-down,quality=70/https://s3.amazonaws.com/zcom-media/sites/a0i0L00000QytFYQAZ/media/mediamanager/Fridge-Filters_Clear_1600x405_.png"
            alt="Fridge Filters Logo"
          />
        </div>
        {formMessage.show ? (
          <div className="text-md mt-5 text-center font-medium sm:mx-auto sm:w-full sm:max-w-sm">
            {formMessage.show ? formMessage.message : null}
          </div>
        ) : (
          <>
            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
              <EmailAuthenticationForm
                loading={loading}
                requestAuthenticationTokenToEmail={
                  requestAuthenticationTokenToEmail
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
