import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { UserIcon } from "@heroicons/react/24/solid";
import RequestEmailAuthentication from "../../views/authentication/RequestEmailAuthentication";
import Subscriptions from "../../views/subscriptions/Subscriptions";

export default function DefaultLayout(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="h-full">
      <header className="bg-main-blue">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-1 lg:px-8 px-3"
          aria-label="Global"
        >
          <div className="flex lg:flex-1 -m-1.5 p-1.5 hidden sm:flex">
            <img
              className="h-10 w-auto"
              src="https://filterfinder.fridge-filters.ca/siteAssets/img/can-flag-3.jpg"
              alt=""
            />
          </div>
          <div className="hidden lg:flex lg:gap-x-12 text-white top-header-middle-title">
            <a href="https://www.fridge-filters.ca">Fridge-Filters.ca</a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end text-white">
            <a
              href="https://www.fridge-filters.ca/customer/account/login/"
              className="top-right-account-button lg:flex"
            >
              Account
              <UserIcon className="h-6 w-6 my-auto ml-1" />
            </a>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="https://fridge-filters.ca" className="-m-1.5 p-1.5">
                <span className="sr-only">Fridge Filters</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6 text-white">
                  <a href="https://www.fridge-filters.ca">Fridge-Filters.ca</a>
                </div>
                <div className="py-6">
                  <a
                    href="https://www.fridge-filters.ca/customer/account/login/"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Account
                  </a>
                  <button
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => props.logout()}
                  >
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <main className="mx-auto max-w-7xl h-full items-center justify-between">
        <Routes>
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="*" element={<Navigate to="/subscriptions" replace />} />
        </Routes>
      </main>
    </div>
  );
}
