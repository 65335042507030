import { Dialog } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function AllAddressesForm(props) {
  const {
    addresses,
    openCreateNewAddressForm,
    setAddressForSubscription,
    deliveryDateId,
  } = props;

  return (
    <div>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Your Addresses
          </Dialog.Title>
          <div className="mt-2">
            <ul className="divide-y divide-gray-100">
              {addresses.map((address) => (
                <li
                  key={address.entity_id}
                  className="relative py-5 hover:bg-gray-50 cursor-pointer"
                  onClick={() => {
                    setAddressForSubscription(
                      address.entity_id,
                      deliveryDateId
                    );
                  }}
                >
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                      <div className="flex gap-x-10">
                        <div className="hidden sm:flex sm:flex-col items-start">
                          <p className="text-sm leading-6 text-gray-900">
                            {address.firstname} {address.lastname}
                          </p>
                          <p className="text-sm leading-6 text-gray-900">
                            {address.street}
                          </p>
                          <p className="text-sm leading-6 text-gray-900">
                            {address.city}, {address.region}, {address.postcode}
                            , {address.country_id}
                          </p>
                          <p className="text-sm leading-6 text-gray-900">
                            Tel: {address.telephone}
                          </p>
                        </div>
                        <ChevronRightIcon
                          className="h-5 w-5 flex-none text-gray-400 mt-auto mb-auto"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => openCreateNewAddressForm(deliveryDateId)}
        >
          Create new address
        </button>
      </div>
    </div>
  );
}
