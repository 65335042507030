import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  authToken: null,
  error: {
    message: null,
    show: false,
  },
  fname: "",
  userId: null,
  storeId: null,
};

const authStart = (state) => {
  return updateObject(state, {
    error: {
      message: null,
      show: false,
    },
    loading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    authToken: action.authToken,
    fname: action.fname,
    userId: action.userId,
    storeId: action.storeId,
    error: {
      message: null,
      show: false,
    },
  });
};

const authFail = (state, action) => {
  if (action.error.data) {
    return updateObject(state, {
      error: {
        message: "We could not authenticate you.",
        show: true,
      },
      loading: false,
    });
  }
  return updateObject(state, {
    error: {
      title: "",
      subtitle: "We could not complete your request, please try again.",
    },
    loading: false,
  });
};

const authLogout = (state) => {
  return updateObject(state, {
    authToken: null,
    fname: null,
    userId: null,
    storeId: null,
    error: {
      message: null,
      show: false,
    },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);

    default:
      return state;
  }
};

export default reducer;
